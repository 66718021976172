import React from "react";
import AdminDashboardTopbar from "../../components/admin-dashboard-components/AdminDashboardTopbar";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import AdminDashboardModules from "../../components/admin-dashboard-components/AdminDashboardModules";

export default function AdminMainDashboardPage() {
  return (
    <>
      <AdminDashboardLayout>
        {/* <AdminDashboardTopbar /> */}
        <AdminDashboardModules />
      </AdminDashboardLayout>
    </>
  );
}
