import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import AlllCompaniesTable from "../../components/companies-management-components/AlllCompaniesTable";


export default function AllCompaniesPage() {
  return (
    <>
      <AdminDashboardLayout>
        <AlllCompaniesTable />
      </AdminDashboardLayout>
    </>
  );
}
