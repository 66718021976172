import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCompanyByID } from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";

export default function SingleCompanyViewCard() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { loading, message, error, data } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getCompanyByID(id));
  }, []);

  return (
    <>
      <div className="flex justify-between  items-center py-5">
        <BreadCrums
          breadCrum={[
            {
              name: "Admin Dashboard",
              path: "/admin-dashboard/",
            },
            {
              name: "Manage Companies",
              path: "/admin-dashboard/manage-companies",
            },
            {
              name: data.name,
            },
          ]}
        />

        {/* <Link
              to="add-company"
              className="text-white bg-usetheme text-xs md:text-sm font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
            >
              Add New Company
            </Link> */}
      </div>
      <div className="w-full xl:w-[70%] flex flex-col gap-4 flex-1 bg-white rounded-lg">
        <div className=" border border-[#EBF0ED] rounded-lg p-[17px]">
          <div className="flex items-center gap-2 mb-4">
            <div className="relative">
              <img
                className="w-14 h-14"
                src={data.logo || "/assets/images/alt-logo.png"}
                alt=""
                onError={(e) => {
                  e.target.onerror = null; // Prevents looping in case the fallback image also fails to load
                  e.target.src = "/assets/images/alt-logo.png"; // Path to your alternative image
                }}
              />
            </div>
            <div></div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-10">
            <div className="w-full">
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  COMPANY NAME :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data.name}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  PHONE NUMBER :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  +9212345678
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  E-MAIL :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data.email}
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  INC :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data.inc}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  LOCATION :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data.address}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-[12px] font-semibold">
                  VAT :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data.vat}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
