import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BreadCrums from "../../common/BreadCrums";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, clearMessage } from "../../store/adminSlice";

export default function AddCompanyForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, statusCode } = useSelector(
    (state) => state.admin
  );
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    domain: "",
    address: "",
    vat: "",
    inc: "",
  });

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { name, email, domain, address, vat, inc } = formData;

    if (!name || !email || !domain || !address || !vat || !inc) {
      toast.error("All fields are required!");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }

    if (!image) {
      toast.error("Please upload a company logo!");
      return false;
    }

    const allowedTypes = ["image/png", "image/jpeg"];
    if (!allowedTypes.includes(image.type)) {
      toast.error("Only PNG or JPEG images are allowed!");
      return false;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (image.size > maxSizeInBytes) {
      toast.error("Image size should not exceed 5MB!");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Create FormData object and append fields
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("domain", formData.domain);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("vat", formData.vat);
      formDataToSend.append("inc", formData.inc);
      formDataToSend.append("logo", image); // Append the image file

      // Dispatch the action with FormData
      dispatch(addCompany(formDataToSend));
    }
  };

  useEffect(() => {
    if (statusCode === 201) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="">
        <div className="flex justify-between  items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Manage Companies",
                path: "/admin-dashboard/manage-companies",
              },
              {
                name: "Add Company",
                path: "/admin-dashboard/manage-companies/add-company",
              },
            ]}
          />
        </div>
      </div>
      <div className="w-full bg-white rounded-xl p-3">
        <h1 className=" text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Add New Company
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Name and email */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Name
              </label>
              <input
                name="name"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Netchain"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            {/* Email */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Email
              </label>
              <input
                name="email"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="email"
                placeholder="netchain@gmail.com"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Domain and Address */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Domain
              </label>
              <input
                name="domain"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="www.abc.com"
                value={formData.domain}
                onChange={handleInputChange}
              />
            </div>

            {/* Address */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Address
              </label>
              <input
                name="address"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Office #408, 4th floor Sheikh Yasin Trade Center Arbab Rd"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Vat and Inc */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Vat
              </label>
              <input
                name="vat"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder=""
                value={formData.vat}
                onChange={handleInputChange}
              />
            </div>

            {/* Inc */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Inc
              </label>
              <input
                name="inc"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder=""
                value={formData.inc}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Upload Logo */}
          <div>
            <div className="max-w-36">
              <h2 className="text-[#6B6B6B] text-[12px] ml-1 font-semibold ">
                Upload Logo
              </h2>
              <div
                onClick={handleImageClick}
                className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative"
              >
                {image ? (
                  <img className="" src={URL.createObjectURL(image)} alt="" />
                ) : (
                  <img
                    className="w-16 h-16 p-4"
                    src="/assets/icons/img-input-2.svg"
                    alt=""
                  />
                )}
                <input
                  ref={inputRef}
                  onChange={handleImageChange}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="text-white bg-usetheme text-xs md:text-sm h-12 font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
          >
            {!loading ? (
              "Add Company"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button>
        </div>
        </form>

      </div>
    </>
  );
}
