import React from "react";

export default function ChangeAdminPassword() {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {/* forms */}
      <div className="w-full bg-white rounded-xl p-3">
        <h1 className="text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Update Password
        </h1>
        <form
          onSubmit={handleSubmit}
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
        >
          {/* Current Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Current Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="cp"
              />
            </div>
          </div>
          {/* New Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                New Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="np"
              />
            </div>
          </div>
          {/* Confirm New Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Confirm New Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="cnp"
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            // type="submit"
            className="bg-usetheme text-white rounded-lg h-10 py-2 px-4 mt-4 hover:bg-usetheme_hover"
          >
            {/* {!loading ? (
              "Save Changes"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )} */}
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
}
