import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import UpdateServiceForm from "../../components/services-management-components/UpdateServiceForm";

export default function UpdateServicePage() {
  return (
    <>
      <AdminDashboardLayout>
        <UpdateServiceForm />
      </AdminDashboardLayout>
    </>
  );
}
