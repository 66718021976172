import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import InvoiceTemplate from "../../components/invoices-management-components/InvoiceTemplate";

export default function InvoiceTemplatePage() {
  return (
    <>
      <AdminDashboardLayout>
        <InvoiceTemplate />
      </AdminDashboardLayout>
    </>
  );
}
