import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  getUserByToken,
  updateUser,
} from "../../store/adminSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import AdminProfileInfoCard from "./AdminProfileInfoCard";
import AdminProfileInfoSettingForm from "./AdminProfileInfoSettingForm";
import ChangeAdminPassword from "./ChangeAdminPassword";

export default function AdminProfileSettingForms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, data, token, statusCode } = useSelector(
    (state) => state.admin
  );

  const handleChangeView = (view) => {
    setCurrentView(view);
  };

  const [currentView, setCurrentView] = useState("editProfile"); // State to manage which component to show

  useEffect(() => {
    if (token) {
      dispatch(getUserByToken(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
      // navigate(-1);
      dispatch(getUserByToken(token));
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between gap-3 w-full">
        {/* Admin Profile Info Card */}
        {/* <AdminProfileInfoCard data={data} /> */}
        {/* show info */}
        <div className="flex flex-col gap-3 w-full lg:w-2/5 bg-white rounded-xl h-64 p-3">
          <div className="border border-[#EBF0ED] rounded-lg p-3 mb-4">
            <div className="flex items-center gap-2 mb-4">
              <div className="relative">
                <img
                  className="w-14 h-14 rounded-full"
                  src={`${
                    data?.picture
                      ? data?.picture
                      : "/assets/icons/img-input-2.svg"
                  }`}
                  alt=""
                />
                <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h2 className="font-semibold text-base text-[#18120F]">
                  {data?.firstName} {data?.lastName}
                </h2>
                <h2 className="text-[12px] font-semibold text-[#6B6B6B]">
                  Web Developer
                </h2>
              </div>
            </div>
            <div>
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  FULL NAME :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.firstName}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  Username :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  @{data?.username}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">Email :</p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {data?.email}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  LOCATION :
                </p>
                <p className="float-end text-xs font-semibold text-[#18120F]">
                  {data?.address}
                </p>
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className=" flex flex-col space-y-2 lg:space-y-0 lg:mt-0 lg:flex-row lg:space-x-2">
            <button
              onClick={() => setCurrentView("editProfile")}
              className={`${currentView === "editProfile" ? 'bg-usetheme text-white' : 'bg-[#c9bdbd] text-white'} rounded-lg py-2 px-4`}
            >
              Edit Profile
            </button>
            <button
              onClick={() => setCurrentView("changePassword")}
              className={`${currentView === "changePassword" ? 'bg-usetheme text-white' : 'bg-[#c9bdbd] text-white'} rounded-lg px-4 py-2`}
            >
              Change Password
            </button>
          </div>
        </div>
        {currentView === 'editProfile' && (
        <AdminProfileInfoSettingForm data={data} />
      )}
          {currentView === 'changePassword' && (
            <ChangeAdminPassword />
             )}
      </div>
    </>
  );
}
