import React from 'react'
import AdminDashboardLayout from '../../layout/AdminDashboardLayout'
import CreateServiceForm from '../../components/services-management-components/CreateServiceForm'

export default function CreateServicePage() {
  return (
    <>
    <AdminDashboardLayout>
        <CreateServiceForm />
    </AdminDashboardLayout>
    </>
  )
}
