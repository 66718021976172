import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import BreadCrums from "../../common/BreadCrums";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  genInvoice,
  getAllActiveUser,
  getCompanies,
  getInvoiceNumber,
  getInvoiceSecretNumber,
} from "../../store/adminSlice";

export default function CreateInvoiceForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    message,
    error,
    data,
    statusCode,
    invoiceNextNumber,
    invoiceSecretNumber,
    companiesForInvoiceForm,
    activeUsers,
  } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getInvoiceNumber());
    dispatch(getInvoiceSecretNumber());
    dispatch(getCompanies());
    dispatch(getAllActiveUser());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    number: invoiceNextNumber?.number,
    secret: invoiceSecretNumber?.secret,
    to: "",
    date: "",
    total: "",
    bonus: "",
    charges: "",
    company: "",
    paymentMonth: "",
    hours: "",
  });

  const isPaymentMonthDisabled = !formData.date;

  useEffect(() => {
    setFormData({
      ...formData,
      number: invoiceNextNumber?.number,
      secret: invoiceSecretNumber?.secret,
    });
  }, [invoiceNextNumber, invoiceSecretNumber]);

  const [tasks, setTasks] = useState([
    { index: 1, taskNumber: "", task: "", detail: "", log: "0" },
  ]);

  const [newTask, setNewTask] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTaskChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, [name]: value } : task
    );
    setTasks(updatedTasks);
  };

  const addTask = () => {
    const newTask = {
      index: tasks.length + 1,
      taskNumber: "",
      task: "",
      detail: "",
      log: "0",
    };
    setTasks([...tasks, newTask]);
  };

  const removeTask = (index) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  const handleDateChange = (event, setField) => {
    const date = event.target.value;
    if (setField === "date") {
      setFormData({
        ...formData,
        date: date,
      });
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "to",
      "date",
      "total",
      "bonus",
      "charges",
      "company",
      "paymentMonth",
      // "hours",
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field} is required`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    console.log("Form submitted with data:", formData, "Tasks:", tasks);
    const formattedPaymentMonth = moment(
      formData.paymentMonth,
      "YYYY-MM"
    ).format("MMMM YYYY");

    const payload = {
      ...formData,
      paymentMonth: formattedPaymentMonth,
      items: tasks,
    };

    dispatch(genInvoice(payload));
  };

  useEffect(() => {
    if (statusCode === 201) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="flex justify-between items-center py-5">
        <BreadCrums
          breadCrum={[
            { name: "Admin Dashboard", path: "/admin-dashboard/" },
            {
              name: "Manage Invoices",
              path: "/admin-dashboard/manage-invoices",
            },
            { name: "Create New Invoices" },
          ]}
        />
        <Link
          to="/create-invoice"
          className="text-white bg-usetheme h-10 text-xs md:text-sm font-semibold py-3 md:py-[10px] px-4 md:px-6 rounded-lg"
          onClick={handleSubmit}
        >
          {!loading ? (
            "Generate Invoice"
          ) : (
            <span className="loading loading-spinner loading-md"></span>
          )}
        </Link>
      </div>

      <div className="w-full bg-white rounded-xl p-3">
        <h1 className="text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Create Invoice
        </h1>

        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Number
              </label>
              <input
                name="number"
                value={formData.number}
                onChange={handleInputChange}
                className="bg-[#f2f2f2] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="number"
                disabled
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Secret
              </label>
              <input
                name="secret"
                value={formData.secret}
                onChange={handleInputChange}
                className="bg-[#f2f2f2] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="text"
                disabled
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="w-full">
              <h2 className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Select Date
              </h2>
              <input
                className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                name="date"
                value={formData.date}
                onChange={(event) => handleDateChange(event, "date")}
                type="date"
                max={moment().add(1, "years").format("YYYY-MM-DD")} // Max one year ahead of the current date
                min={moment().subtract(1, "years").format("YYYY-MM-DD")} // Max one year back from the current date
              />
            </div>
            <div className="w-full">
              <h2 className="text-[#6B6B6B] text-[12px] ml-1 font-semibold ">
                Payment Month
              </h2>
              <input
                className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                name="paymentMonth"
                value={formData.paymentMonth}
                onChange={handleInputChange}
                type="month"
                disabled={isPaymentMonthDisabled} // Disable if date is not selected
                min={
                  formData.date
                    ? moment(formData.date).format("YYYY-MM")
                    : moment().subtract(1, "years").format("YYYY-MM")
                } // min based on date
                max={moment().add(1, "years").format("YYYY-MM")} // max one year from now
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="block w-full">
              <label
                htmlFor="company"
                className="block mb-1 text-[#6B6B6B] text-[12px] ml-1 font-semibold"
              >
                Company (Sender)
              </label>
              <select
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                className="h-12 border border-gray-300 text-gray-600 text-base rounded-lg block w-full py-2.5 px-4 focus:outline-none appearance-none"
              >
                <option value="">Choose a company</option>
                {/* Mapping through companiesForInvoiceForm */}
                {companiesForInvoiceForm?.companies?.map((company) => (
                  <option key={company._id} value={company._id}>
                    {company.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="block w-full">
              <label
                htmlFor="to"
                className="block mb-1 text-[#6B6B6B] text-[12px] ml-1 font-semibold"
              >
                To (Reciever)
              </label>
              <select
                id="to"
                name="to"
                value={formData.to}
                onChange={handleInputChange}
                className="h-12 border border-gray-300 text-gray-600 text-base rounded-lg block w-full py-2.5 px-4 focus:outline-none"
              >
                <option value="">Choose an option</option>
                {activeUsers?.map((user) => (
                  <option key={user._id} value={user.username}>
                    {user.username}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
              Tasks
            </label>

            {tasks.map((task, index) => (
              <div key={index} className="border p-2 rounded-lg mb-2">
                <div className="flex gap-2">
                  <div className="flex flex-col">
                    <label className="text-[#6B6B6B] text-[12px] font-semibold">
                      Task Number
                    </label>
                    <input
                      name="taskNumber"
                      value={task.taskNumber}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="text"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-[#6B6B6B] text-[12px] font-semibold">
                      Task
                    </label>
                    <input
                      name="task"
                      value={task.task}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="text"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-[#6B6B6B] text-[12px] font-semibold">
                      Detail
                    </label>
                    <input
                      name="detail"
                      value={task.detail}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="text"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-[#6B6B6B] text-[12px] font-semibold">
                      Logged Hours
                    </label>
                    <input
                      name="log"
                      value={task.log}
                      onChange={(event) => handleTaskChange(index, event)}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 outline-none"
                      type="number"
                      min={0}
                    />
                  </div>

                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => removeTask(index)}
                  >
                    <AiOutlineDelete />
                  </button>
                </div>
              </div>
            ))}

            <div className="flex gap-2">
              <button
                type="button"
                className="text-white bg-usetheme py-2 px-4 rounded-lg"
                onClick={addTask}
              >
                Add Task
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Logged Hours
              </label>
              <input
                name="hours"
                value={formData.hours}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Charges
              </label>
              <input
                name="charges"
                value={formData.charges}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Bonus
              </label>
              <input
                name="bonus"
                value={formData.bonus}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>

            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Total
              </label>
              <input
                name="total"
                value={formData.total}
                onChange={handleInputChange}
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="number"
                min={0}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Gross Total
              </label>
              <input
                name="grossTotal"
                value={
                  Number(formData.total || 0) +
                  Number(formData.bonus || 0) +
                  Number(formData.charges || 0)
                }
                onChange={handleInputChange}
                className="bg-[#f2f2f2] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="number"
                disabled
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
