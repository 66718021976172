import AdminProfileSettingPage from "../pages/admin-profile-setting/AdminProfileSettingPage";


const profileSettingRoutes = [
  {
    path: 'profile-setting',
    element: <AdminProfileSettingPage />,
  },
];

export default profileSettingRoutes;
