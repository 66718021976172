import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import BreadCrums from "../../common/BreadCrums";
import SearchBox from "../../common/SearchBox";
import AllInvoicesTable from "../../components/invoices-management-components/AllInvoicesTable";
import { Link } from "react-router-dom";

export default function AllInvoicesPage() {
  return (
    <>
      <AdminDashboardLayout>
        <AllInvoicesTable />
      </AdminDashboardLayout>
    </>
  );
}
