import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";


const authRoutes = [
    {  
        index: true,
        element: <LoginPage />
    },
    { 
        path: "/signup",
        element: <SignupPage />
    },
    { 
        path: "/forgot-password",
        element: <ForgotPasswordPage />
    },
];

export default authRoutes;