import React from "react";
import { Link } from "react-router-dom";

export default function ButtonLink({
    to = "",
    text = "button",
    activeState = "text-white bg-usetheme",
    hoverState= "hover:bg-usetheme_hover",
    padding = "py-2 px-3 md:px-3 md:py-2",
    textSize = "text-xs md:text-sm",
    fontSize = "font-semibold",
    borderRadius = "rounded-md",
    onClick,
}) {
  return (
    <>
      <Link
      onClick={onClick}
        to={to}
        className={` ${activeState} ${hoverState} ${padding} ${textSize} ${fontSize} ${borderRadius} `}
      >
        {text}
      </Link>
    </>
  );
}
