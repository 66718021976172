import AllInvoicesPage from "../pages/invoices-management/AllInvoicesPage";
import CreateInvoicePage from "../pages/invoices-management/CreateInvoicePage";
import InvoiceTemplatePage from "../pages/invoices-management/InvoiceTemplatePage";



const invoicesManagementRoutes = [
  {
    path: 'manage-invoices',
    element: <AllInvoicesPage />,
  },
  {
    path: 'manage-invoices/create-invoice',
    element: <CreateInvoicePage />,
  },
  {
    path: 'manage-invoices/view-invoice/:id',
    element: <InvoiceTemplatePage />,
  },
];

export default invoicesManagementRoutes;
