import React, { useState } from "react";
import { CiMenuFries } from "react-icons/ci";
import { RiDashboardFill } from "react-icons/ri";
import { IoSettingsOutline, IoPersonAdd } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { RiCloseLargeLine } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import Drawer from "../components/drawer/Drawer";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import { GoPerson } from "react-icons/go";

const AdminDashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen((prev) => !prev);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative flex h-screen bg-gray-100">
      {/* Sidebar start here */}
      <div
        className={`fixed inset-y-0 left-0 bg-white border-r transition-all duration-300 z-20 ${
          isSidebarOpen ? "w-56" : "w-16"
        }`}
      >
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="m-4 focus:outline-none"
        >
          {isSidebarOpen ? (
            <RiCloseLargeLine size={30} /> // Cross icon when open
          ) : (
            <CiMenuFries size={30} /> // Menu icon when closed
          )}
        </button>
        <div className="mt-10">
          <NavLink
            to="/admin-dashboard"
            end
            className={({ isActive }) =>
              `flex items-center p-4 cursor-pointer  ${
                isActive
                  ? "bg-usetheme text-white"
                  : "text-black hover:bg-usetheme hover:text-white"
              }`
            }
          >
            <SidebarItem
              icon={<RiDashboardFill size={30} />}
              title="Dashboard"
              isOpen={isSidebarOpen}
            />
          </NavLink>
          <NavLink
            to="/admin-dashboard/services"
            className={({ isActive }) =>
              `flex items-center p-4 cursor-pointer  ${
                isActive
                  ? "bg-usetheme text-white"
                  : "text-black hover:bg-usetheme hover:text-white"
              }`
            }
          >
            <SidebarItem
              icon={<MdOutlineMiscellaneousServices size={30} />}
              title="Services"
              isOpen={isSidebarOpen}
            />
          </NavLink>
          <NavLink
            to="/admin-dashboard/all-users"
            className={({ isActive }) =>
              `flex items-center p-4 cursor-pointer  ${
                isActive
                  ? "bg-usetheme text-white"
                  : "text-black hover:bg-usetheme hover:text-white"
              }`
            }
          >
            <SidebarItem
              icon={<IoPersonAdd size={30} />}
              title="All Users"
              isOpen={isSidebarOpen}
            />
          </NavLink>
          {/* <NavLink
            to="/admin-dashboard/profile-setting"
            className={({ isActive }) =>
              `flex items-center p-4 cursor-pointer  ${
                isActive
                  ? "bg-usetheme text-white"
                  : "text-black hover:bg-usetheme hover:text-white"
              }`
            }
          >
            <SidebarItem
              icon={<FaUserEdit size={30} />}
              title="Profile"
              isOpen={isSidebarOpen}
            />
          </NavLink> */}
          <NavLink
            to="/admin-dashboard/settings"
            className={({ isActive }) =>
              `flex items-center p-4 cursor-pointer  ${
                isActive
                  ? "bg-usetheme text-white"
                  : "text-black hover:bg-usetheme hover:text-white"
              }`
            }
          >
            <SidebarItem
              icon={<IoSettingsOutline size={30} />}
              title="Setting"
              isOpen={isSidebarOpen}
            />
          </NavLink>
          {/* <NavLink
            to="/"
            className={({ isActive }) =>
              `flex items-center p-4 cursor-pointer  ${
                isActive
                  ? "bg-usetheme text-white"
                  : "text-black hover:bg-usetheme hover:text-white"
              }`
            }
          >
            <SidebarItem
              icon={<LuLogOut size={30} />}
              title="Logout"
              isOpen={isSidebarOpen}
            />
          </NavLink> */}
        </div>
      </div>
      {/* Sidebar end here */}

      {/* Main content area start here */}
      <div className="flex-1 flex flex-col ml-16">
        {/* Header */}
        <header className="bg-white p-3 shadow-md px-10">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-lg font-bold">OFFICE</h1>
            </div>
            <div className="flex gap-4 items-center">
              <div>
                <Drawer />
              </div>
              <div className="">
                <div className="dropdown dropdown-end">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-ghost rounded-btn"
                    onClick={toggleDropdown}
                  >
                    <img
                      className="bg-[#F7F7F7] p-2 rounded-full"
                      src="/assets/icons/notification.svg"
                      alt="Notifications"
                    />
                  </div>
                  {isOpen && (
                    <ul
                      className="menu dropdown-content bg-white rounded-box z-[1] mt-4 w-52 p-1 shadow absolute"
                      onClick={closeDropdown}
                    >
                      <li>
                        <span>netchain joins today</span>
                      </li>
                      <li>
                        <span>netchain generate on contract</span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              {/* User Dropdown */}
              <div className="relative">
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={toggleUserDropdown}
                >
                  <span className="">
                    {localStorage.getItem("fullName") &&
                    localStorage.getItem("fullName") !== "undefined undefined"
                      ? localStorage.getItem("fullName")
                      : "Hello User"}
                  </span>{" "}
                  <img
                    className="w-8 h-8 rounded-full border border-usetheme"
                    src={
                      localStorage.getItem("picture") && localStorage.getItem("picture") !== "undefined" && localStorage.getItem("picture") !== null
                        ? localStorage.getItem("picture")
                        : "/assets/icons/avatar-icon-2.svg"
                    }
                    alt="User Avatar"
                  />
                  {/* Username */}
                  <MdExpandLess
                    className={`transition-transform ${
                      isUserDropdownOpen ? "rotate-0" : "rotate-180"
                    }`}
                  />
                </div>
                {isUserDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
                    <ul className="py-2">
                      <Link to="/admin-dashboard/profile-setting">
                        <li className="flex items-center p-2 hover:bg-gray-100 cursor-pointer border-b-2">
                          <GoPerson className="w-5 h-5 mr-2" />
                          <span>Profile Setting</span>
                        </li>
                      </Link>
                      <Link to="/">
                        <li className="flex items-center p-2 hover:bg-gray-100 cursor-pointer ">
                          <MdOutlinePowerSettingsNew className="w-5 h-5 mr-2" />
                          <span>Sign Out</span>
                        </li>
                      </Link>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        {/* here the children will come and the sidebar and header will be the same */}
        <main className="p-4 overflow-y-auto h-[80vh]">{children}</main>
        <footer className="footer footer-center bg-base-300 text-base-content p-2 font-semibold">
          <aside>
            <p>
              Copyright © {new Date().getFullYear()} - All right reserved by
              <span className="font-bold">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://netchainmedia.com/"
                >
                  {" "}
                  NetChain Media
                </a>
              </span>
            </p>
          </aside>
        </footer>
      </div>
      {/* Main content area end here */}
    </div>
  );
};

const SidebarItem = ({ icon, title, isOpen }) => {
  return (
    <>
      <span className="text-xl">{icon}</span>
      {isOpen && <span className="ml-4">{title}</span>}
    </>
  );
};

export default AdminDashboardLayout;
