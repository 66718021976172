import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import SingleCompanyViewCard from "../../components/companies-management-components/SingleCompanyViewCard";
import BreadCrums from "../../common/BreadCrums";

export default function SingleCompanyView() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="px-4">
          <SingleCompanyViewCard />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
