import React from "react";
import AllUsers from "../../components/user-management-components/AllUsers";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import BreadCrums from "../../common/BreadCrums";
import { Link } from "react-router-dom";
import ButtonLink from "../../components/buttons/ButtonLink";

export default function AllUsersPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="px-4">
          <div className="">
            <div className="flex justify-between  items-center py-5">
              <BreadCrums
                breadCrum={[
                  {
                    name: "Admin Dashboard",
                    path: "/admin-dashboard/",
                  },
                  {
                    name: "All Users",
                  },
                ]}
              />
              <div>
                {/* <Link
                  to="/admin-dashboard/all-users/new-users"
                  className="text-white bg-usetheme text-xs md:text-sm font-semibold py-[7px] md:py-[10px] px-5 md:px-5 rounded-lg"
                >
                  Non Active Users
                </Link> */}
                <ButtonLink to="/admin-dashboard/all-users/new-users" text="Non Active Users" />
              </div>
            </div>
          </div>
          <AllUsers />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
