import AllUsersPage from "../pages/user-management/AllUsersPage";
import NewUsersPage from "../pages/user-management/NewUsersPage";


const userManagementRoutes = [
  {
    path: 'all-users',
    element: <AllUsersPage />,
  },
  {
    path: '/admin-dashboard/all-users/new-users',
    element: <NewUsersPage />,
  },
];

export default userManagementRoutes;
