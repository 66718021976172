import AdminMainDashboardPage from "../pages/admin-dashboard/AdminMainDashboardPage";

const AdminDashboardRoute = [
  {
    index: true,
    element: <AdminMainDashboardPage />,
  },
];

export default AdminDashboardRoute;
