import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import ServicesManagement from "../../components/services-management-components/ServicesManagement";
import BreadCrums from "../../common/BreadCrums";
import { Link } from "react-router-dom";
import ButtonLink from "../../components/buttons/ButtonLink";

export default function AllServicesPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="px-4">
          <div className="">
            <div className="flex justify-between  items-center py-5">
              <BreadCrums
                breadCrum={[
                  {
                    name: "Admin Dashboard",
                    path: "/admin-dashboard/",
                  },
                  {
                    name: "Services",
                    path: "/admin-dashboard/services",
                  },
                ]}
              />
              <ButtonLink to="add-service" text="Add New Service" />
            </div>
          </div>

          <ServicesManagement />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
