import React, { useEffect, useState } from "react";
import AdmindashboardModulesCard from "./AdmindashboardModulesCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getUserByToken,
  searchService,
} from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";
import Loader from "../../common/Loader";

export default function AdminDashboardModules() {
  const dispatch = useDispatch();
  const { token, services, loading } = useSelector((state) => state.admin);

    // State to store search query and timer
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    dispatch(getUserByToken(token));
    dispatch(getAllServices());
  }, [dispatch, token]);

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    // Clear the previous timeout if a new search is triggered
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout for 3 seconds before triggering the search
    const timeout = setTimeout(() => {
      if (query === "") {
        dispatch(getAllServices());  // Fetch all services if search is cleared
      } else {
        dispatch(searchService(query));  // Trigger the search action
      }
    }, 1000);  // 3 seconds debounce delay

    // Update the searchTimeout state with the new timeout ID
    setSearchTimeout(timeout);
  };

  return (
    <>
      <div className="px-4">
        <div className="flex justify-between items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/",
              },
            ]}
          />
        </div>
        <div className="relative flex items-center w-full">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
            type="search"
            placeholder="Search Module"
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="px-4 py-4 mt-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div
            className={`grid md:grid-cols-3 xl:grid-cols-4 grid-cols-2`}
          >
            {services?.services?.length > 0
              ? services?.services?.map((item) => (
                  <AdmindashboardModulesCard key={item._id} item={item} />
                ))
              : "No Services Found"}
          </div>
        )}
      </div>
    </>
  );
}
