import React, { useEffect } from "react";
import { FaDownload } from "react-icons/fa"; // For the download icon (optional)
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInvoiceByID } from "../../store/adminSlice";
import html2pdf from "html2pdf.js"; // Import the library for PDF generation
import BreadCrums from "../../common/BreadCrums";

export default function InvoiceTemplate() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { loading, message, error, data } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getInvoiceByID(id));
  }, [dispatch, id]);

  const handleDownload = () => {
    const invoiceElement = document.getElementById("invoice"); // Get the invoice element by ID
    if (!invoiceElement) {
      console.error("Invoice element not found");
      return;
    }

    const options = {
      margin: 1,
      filename: `invoice-${data?.number}.pdf`, // Set the filename
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(invoiceElement).set(options).save();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

//   <style>
//   {`
//     @media print {
//       .no-print {
//         display: none;
//       }
//     }
//   `}
// </style>

  return (
    <>

      <div className="flex justify-between items-center py-5">
        <BreadCrums
          breadCrum={[
            { name: "Admin Dashboard", path: "/admin-dashboard/" },
            {
              name: "Manage Invoices",
              path: "/admin-dashboard/manage-invoices",
            },
            { name: "View Invoice" },
          ]}
        />
      </div>
      <div
        id="invoice"
        className="max-w-4xl mx-auto bg-white shadow-lg p-8 my-10 border border-gray-200 rounded-lg"
      >
        {/* Company Header */}
        <div className="flex justify-between items-center mb-6 border-b pb-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Netchain Media</h1>
            <p className="text-gray-500">
              Office #408, 4th floor Sheikh Yasin Trade Center Arbab Rd, Tahkal
              Peshawar, Khyber Pakhtunkhwa 25000
            </p>
            <p className="text-gray-500">
              Email: info@netchainmedia.com | Phone: +92 333 333 6416
            </p>
          </div>
          <div>
            <img
              src="/assets/images/netchain-logo.svg"
              alt="Company Logo"
              className="w-24 h-24 object-contain"
            />
          </div>
        </div>

        {/* Invoice Info */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-700">
            Invoice # {data?.number}
          </h2>
          <p className="text-gray-500">
            Invoice Date: {new Date(data.date).toLocaleDateString()}
          </p>
          <p className="text-gray-500">Payment Month: {data?.paymentMonth}</p>
        </div>

        {/* Recipient Info */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Invoice To:</h3>
            <p className="text-gray-500">{data.toLabel}</p>
            <p className="text-gray-500">{data.toRoleLabel}</p>
            <p className="text-gray-500">{data.to}</p>
          </div>
          <div className="text-right">
            <h3 className="text-lg font-semibold text-gray-700">
              Invoice From:
            </h3>
            <p className="text-gray-500">Netchain Media</p>
            <p className="text-gray-500">12345678</p>
            <p className="text-gray-500">Peshawar, Pakistan</p>
          </div>
        </div>

        {/* Task List */}
        <div className="border border-gray-300 rounded-lg overflow-hidden mb-6">
          <table className="min-w-full table-auto text-left">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 font-semibold text-gray-700">#</th>
                <th className="px-4 py-2 font-semibold text-gray-700">
                  Task Number
                </th>
                <th className="px-4 py-2 font-semibold text-gray-700">Task</th>
                <th className="px-4 py-2 font-semibold text-gray-700">
                  Details
                </th>
                <th className="px-4 py-2 font-semibold text-gray-700">
                  Hours Logged
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.items?.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{item.index}</td>
                  <td className="px-4 py-2">{item.taskNumber}</td>
                  <td className="px-4 py-2">{item.task}</td>
                  <td className="px-4 py-2">{item.detail}</td>
                  <td className="px-4 py-2">{item.log} hrs</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Invoice Summary */}
        <div className="flex justify-end mb-6">
          <div className="w-full md:w-1/2 lg:w-1/3">
            <div className="flex justify-between items-center py-2">
              <p className="text-gray-600">Bonus</p>
              <p className="font-semibold text-gray-800">${data.bonus}</p>
            </div>
            <div className="flex justify-between items-center py-2">
              <p className="text-gray-600">Charges</p>
              <p className="font-semibold text-gray-800">${data.charges}</p>
            </div>
            <div className="flex justify-between items-center py-2 border-t">
              <p className="text-xl font-semibold text-gray-700">Total</p>
              <p className="text-xl font-bold text-gray-900">
                ${data.charges + data.total}
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center border-t pt-4 text-gray-600">
          <p>Thank you for your business!</p>
          <p>
            If you have any questions, feel free to contact us at
            info@netchainmedia.com
          </p>
        </div>

        {/* Download Button */}
        <div className="flex justify-center mt-8 no-print">
          <button
            onClick={handleDownload}
            className="flex items-center text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded"
          >
            <FaDownload className="mr-2" /> Download Invoice
          </button>
        </div>
      </div>
    </>
  );
}
