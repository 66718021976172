import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import { ACTIVE_USERS, ADMIN_LOGIN, COMPANIES, CORE, INACTIVE_USERS, INVOICES, RECOVER_PASSWORD, SECRET_NUMBER, SERVICES, SIGNUP, UPDATE_CORE, UPDATE_USER, UPDATE_USER_STATUS, USER_BY_TOKEN } from "./URLConstant";
import axios from '../functions/axios'


const initialState = {
    data: [],
    loading: false,
    message: null,
    error: null,
    statusCode: null,
    token: localStorage.getItem("token"),
    role: localStorage.getItem("role"),
    signout: false,
    invoiceNextNumber: null,
    invoiceSecretNumber: null,
    companiesForInvoiceForm: null,
    activeUsers: null,
    services: null,
};

// *********** RTK Middleware Stare here ***********

// admin Login
export const adminLogin = createAsyncThunk(
    "adminLogin",
    async (login_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(ADMIN_LOGIN, login_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// signup
export const Signup = createAsyncThunk(
    "Signup",
    async (signup_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(SIGNUP, signup_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// forgot Password
export const forgotPassword = createAsyncThunk(
    "forgotPassword",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${RECOVER_PASSWORD}/${data}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get All Companies
export const getCompanies = createAsyncThunk(
    "getCompanies",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(COMPANIES);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get compnay by id
export const getCompanyByID = createAsyncThunk(
    "getCompanyByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${COMPANIES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// add company
export const addCompany = createAsyncThunk(
    "addCompany",
    async (company_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(COMPANIES, company_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update company
export const updateCompany = createAsyncThunk(
    "updateCompany",
    async (company_details, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${COMPANIES}/${company_details?.id}`, company_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// delete company
export const deleteCompany = createAsyncThunk(
    "deleteCompany",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${COMPANIES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// search companies
export const searchCompany = createAsyncThunk(
    "searchCompany",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${COMPANIES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get user by token
export const getUserByToken = createAsyncThunk(
    "getUserByToken",
    async (user_token, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${USER_BY_TOKEN}/${user_token}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update user
export const updateUser = createAsyncThunk(
    "updateUser",
    async (user_details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`${UPDATE_USER}/${user_details?.id}`, user_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// Core company setting
export const getCoreCompanySetting = createAsyncThunk(
    "getCoreCompanySetting",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(CORE);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// update core company setting
export const updateCoreCompanySetting = createAsyncThunk(
    "updateCoreCompanySetting",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.put(UPDATE_CORE, data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all services
export const getAllServices = createAsyncThunk(
    "getAllServices",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(SERVICES);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// add service
export const addService = createAsyncThunk(
    "addService",
    async (service_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(SERVICES, service_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update service
export const updateService = createAsyncThunk(
    "updateService",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${SERVICES}/${data.id}`, data.formData);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get service by id
export const getServiceByID = createAsyncThunk(
    "getServiceByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SERVICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// delete services
export const deleteService = createAsyncThunk(
    "deleteService",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${SERVICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// search services
export const searchService = createAsyncThunk(
    "searchService",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SERVICES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get All Invoices
export const getInvoices = createAsyncThunk(
    "getInvoices",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(INVOICES);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice by id
export const getInvoiceByID = createAsyncThunk(
    "getInvoiceByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice number
export const getInvoiceNumber = createAsyncThunk(
    "getInvoiceNumber",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/next`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice number
export const getInvoiceSecretNumber = createAsyncThunk(
    "getInvoiceSecretNumber",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SECRET_NUMBER}/32`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// generate invoice
export const genInvoice = createAsyncThunk(
    "genInvoice",
    async (invoice_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(INVOICES, invoice_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// search invoice
export const searchInvoice = createAsyncThunk(
    "searchInvoice",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all user
export const getAllActiveUser = createAsyncThunk(
    "getAllActiveUser",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(ACTIVE_USERS);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all inactive user
export const getAllInactiveUser = createAsyncThunk(
    "getAllInactiveUser",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(INACTIVE_USERS);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update user status
export const updateUserStatus = createAsyncThunk(
    "updateUserStatus",
    async (details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`${UPDATE_USER_STATUS}/${details.id}` ,{ isActive: details.isActive });
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);


// *********** RTK Middleware End here ***********

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
      clearMessage: (state) => {
        state.message = null;
        state.error = null;
        state.statusCode = null;
      },
      resetSignout: (state) => {
        state.signout = false;
      },
      signoutUser: (state) => {
        localStorage.clear();
        state.token = null;
        state.role = null;
        state.signout = true;
      },
    },
    extraReducers: (builder) => {



      //   *********** admin Add-Cases Start Here    ***********
  
      // admin login
      builder
        .addCase(adminLogin.pending, (state) => {
            state.loading = true;
        })
        .addCase(adminLogin.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.token = payload.data.token;
            console.log('sds', payload.data)
            state.statusCode = payload.statusCode;

            localStorage.setItem("token", payload.data.token);

        })
        .addCase(adminLogin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
            state.statusCode = action.payload?.statusCode;
        })
        .addCase(Signup.pending, (state) => {
            state.loading = true;
        })
        .addCase(Signup.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;

        })
        .addCase(Signup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
            state.statusCode = action.payload?.statusCode;
        })
        .addCase(forgotPassword.pending, (state) => {
            state.loading = true;
        })
        .addCase(forgotPassword.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(forgotPassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
            state.statusCode = action.payload?.statusCode;
        })
        .addCase(getCompanies.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCompanies.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.companiesForInvoiceForm = payload.data
        })
        .addCase(getCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getCompanyByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCompanyByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getCompanyByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(addCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(addCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(addCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getUserByToken.pending, (state) => {
            state.loading = true;
        })
        .addCase(getUserByToken.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            localStorage.setItem("fullName", payload.data.firstName + " " + payload.data.lastName);
            localStorage.setItem("picture", payload.data.picture);
        })
        .addCase(getUserByToken.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getCoreCompanySetting.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCoreCompanySetting.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getCoreCompanySetting.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateCoreCompanySetting.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateCoreCompanySetting.fulfilled, (state, { payload }) => {
            state.loading = false;
            // state.data = payload.data;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateCoreCompanySetting.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllServices.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllServices.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.services = payload.data;
        })
        .addCase(getAllServices.rejected, (state, action) => {
            state.loading = false;
        })
        .addCase(addService.pending, (state) => {
            state.loading = true;
        })
        .addCase(addService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(addService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateService.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            // state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getServiceByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getServiceByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getServiceByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteService.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchService.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.services = payload.data;
        })
        .addCase(searchService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoices.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoices.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getInvoices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getInvoiceByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceNumber.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceNumber.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.invoiceNextNumber = payload.data;
        })
        .addCase(getInvoiceNumber.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceSecretNumber.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceSecretNumber.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.invoiceSecretNumber = payload.data;
        })
        .addCase(getInvoiceSecretNumber.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(genInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(genInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(genInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllActiveUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllActiveUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.activeUsers = payload.data;
        })
        .addCase(getAllActiveUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllInactiveUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllInactiveUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getAllInactiveUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateUserStatus.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateUserStatus.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
        })
        .addCase(updateUserStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        
      //   *********** admin Add-Cases End Here    ***********

    }
  });


export const { clearMessage, resetSignout, signoutUser } = adminSlice.actions;
export default adminSlice.reducer;