import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";
import BreadCrums from "../../common/BreadCrums";
import NewUsers from "../../components/user-management-components/NewUsers";

export default function NewUsersPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="px-4">
          <div className="">
            <div className="flex justify-between  items-center py-5">
              <BreadCrums
                breadCrum={[
                  {
                    name: "Admin Dashboard",
                    path: "/admin-dashboard/",
                  },
                  {
                    name: "All Users",
                    path: "/admin-dashboard/all-users"
                  },
                  {
                    name: "New Users",
                  },
                ]}
              />

              {/* <Link
                to=""
                className="text-white bg-usetheme text-xs md:text-sm font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
              >
                Update Details
              </Link> */}
            </div>
          </div>
          <NewUsers />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
