import SettingPage from "../pages/setting-pages/SettingPage";


const settingRoutes = [
  {
    path: 'settings',
    element: <SettingPage />,
  },
];

export default settingRoutes;
