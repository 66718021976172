import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function AdmindashboardModulesCard({ item }) {
  return (
    <>
      <div class="hover:-translate-y-2 duration-500 w-64 bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-9 space-y-3 relative overflow-hidden">
        <div class="w-24 h-24 bg-usetheme rounded-full absolute -right-5 -top-7">
          <p class="absolute bottom-6 left-7 text-white text-2xl cursor-pointer">
            <Link to={item.addButton}>
              <FaPlus />
            </Link>
          </p>
        </div>
        <div class="fill-violet-500 w-12">
          <img src={item.icon} alt="" />
        </div>
        <h1 class="font-bold text-xl">{item.title}</h1>
        <p class="text-sm text-zinc-500 leading-6">{item.description}</p>
        <div class="flex px-2 pb-2 pt-4 sm:px-2">
          <Link
            aria-describedby="tier-company"
            class="flex items-center justify-center w-full px-6 py-2.5 text-center text-usetheme font-semibold tracking-wide duration-200 bg-white border-2 border-usetheme rounded-full nline-flex hover:bg-usetheme hover:border-usetheme hover:text-white focus:outline-none focus-visible:outline-usetheme text-sm focus-visible:ring-usetheme"
            to={item.buttonLink}
          >
            {item.buttonText}
          </Link>
        </div>
      </div>
    </>
  );
}
