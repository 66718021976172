export const ADMIN_LOGIN = "users/public/login";
export const SIGNUP = "users/public";
export const RECOVER_PASSWORD = "users/public/recover-password";
export const COMPANIES = "companies";
export const USER_BY_TOKEN = "users/token";
export const UPDATE_USER = "users";
export const CORE = "core";
export const UPDATE_CORE = "core/update";
export const SERVICES = "services";
export const INVOICES = "invoices";
export const SECRET_NUMBER = "misc/secret";
export const ACTIVE_USERS = "users/active";
export const INACTIVE_USERS = "users/inactive";
export const UPDATE_USER_STATUS = "users/status";