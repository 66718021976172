import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, updateUserStatus } from "../../store/adminSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function NewUsersCard({ item }) {
  const dispatch = useDispatch();



  return (
    <>
      <div className="flex gap-2 ">
        <div className="flex flex-col gap-3 w-full  bg-white rounded-xl p-3">
          <div className="border border-[#EBF0ED] rounded-lg px-3 py-2">
            <div className="flex items-center gap-2 "></div>
            <div>
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  Username :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  @{item?.username}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">Email :</p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {item?.email}
                </p>
              </div>
              <hr />
              <div className="flex items-center gap-1 my-1 mt-2">
                <div
                  onClick={() =>
                    dispatch(updateUserStatus({ id: item._id, isActive: true }))
                  }
                  className="px-3 py-1 bg-transparent border border-usetheme text-usetheme rounded-lg cursor-pointer"
                >
                  Approve
                </div>
                <div className="px-3 py-1 bg-usetheme hover:bg-transparent text-white hover:text-usetheme border hover:border-usetheme transition duration-300 rounded-lg cursor-pointer">
                  Reject
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
