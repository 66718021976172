import { configureStore } from "@reduxjs/toolkit";
import adminReducer from './adminSlice'


const store = configureStore({
    reducer: {
        admin: adminReducer // Using the reducer directly
    }
});

export default store;