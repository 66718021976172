import React, { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import { getInvoices, searchInvoice } from "../../store/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrums from "../../common/BreadCrums";
import ButtonLink from "../buttons/ButtonLink";

export default function AllInvoicesTable() {
  const dispatch = useDispatch();
  const { loading, message, error, data, statusCode } = useSelector(
    (state) => state.admin
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    // Clear the previous timeout if a new search is triggered
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeout = setTimeout(() => {
      if (query === "") {
        dispatch(getInvoices());
      } else {
        dispatch(searchInvoice(query));
      }
    }, 1000);
    setSearchTimeout(timeout);
  };

  useEffect(() => {
    dispatch(getInvoices());
  }, []);
  return (
    <>
      <div className="px-4">
        <div className="flex justify-between items-center py-5">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Manage Invoices",
              },
            ]}
          />
          {/* <Link
            to="create-invoice"
            className="text-white bg-usetheme text-xs md:text-sm font-semibold py-[7px] md:py-[10px] px-5 md:px-6 rounded-lg"
          >
            Add New Invoice
          </Link> */}
          <ButtonLink to="create-invoice" text="Add New Invoice" />
        </div>
        <div className="relative flex items-center w-full">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
            type="search"
            placeholder="Search Invoices .."
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="mt-5 px-4">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            {/* head */}
            <thead>
              <tr className="bg-usetheme text-white">
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Number
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Reciever
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Role
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Date
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Total
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Bonus
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Charges
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan="8">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.invoices?.length > 0 ? (
                data?.invoices?.map((inc) => (
                  <tr className="hover:bg-gray-100">
                    <td className="py-4 px-6 text-sm text-gray-700">
                      #{inc.number}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      {inc.to}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      {inc.toRoleLabel}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      {inc.paymentMonth}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      ${inc.total}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      ${inc.bonus}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-700">
                      ${inc.charges}
                    </td>
                    <td className="mt-2 px-2 md:px-3  flex items-center gap-2">
                      {/* <Link>
                        <img
                          className="w-8 h-8 md:w-16 md:h-16"
                          src="/assets/icons/edit.svg"
                          alt="Edit"
                        />
                      </Link> */}
                      <Link to={`view-invoice/${inc._id}`}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/view.svg"
                          alt="View"
                        />
                      </Link>
                      <Link>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/delete.svg"
                          alt="Delete"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">
                    <div className="text-center font-bold tracking-wide py-2">
                      No Invoices Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
