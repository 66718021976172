import AddCompanyPage from "../pages/companies-management/AddCompanyPage";
import AllCompaniesPage from "../pages/companies-management/AllCompaniesPage";
import SingleCompanyView from "../pages/companies-management/SingleCompanyView";
import UpdateCompanyPage from "../pages/companies-management/UpdateCompanyPage";


const companiesManagementRoutes = [
  {
    path: 'manage-companies',
    element: <AllCompaniesPage />,
  },
  {
    path: 'manage-companies/add-company',
    element: <AddCompanyPage />,
  },
  {
    path: 'manage-companies/view-company/:id',
    element: <SingleCompanyView />,
  },
  {
    path: 'manage-companies/update-company/:id',
    element: <UpdateCompanyPage />,
  },
];

export default companiesManagementRoutes;
