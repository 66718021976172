import React from "react";

export default function Drawer() {
  return (
    <>
      <div className="drawer drawer-end z-40">
        <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <label
            htmlFor="my-drawer-4"
            className="drawer-button text-sm text-usetheme cursor-pointer"
          >
            What's New?
          </label>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu bg-white text-base-content min-h-full w-80 p-4">
            <li>
              <a>Added Approve user page</a>
            </li>
            <li>
              <a>Update the ui</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
