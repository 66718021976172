import React, { useState, useEffect } from "react";

export default function ColorPicker({ onColorChange }) {
  const [color, setColor] = useState("");
  const [recentColors, setRecentColors] = useState(() => {
    // Get from localStorage or default to empty array
    const savedColors = localStorage.getItem("recentColors");
    return savedColors ? JSON.parse(savedColors) : [];
  });

  const handleColorChange = () => {
    if (color) {
      // Add the new color to the recent colors list
      const updatedColors = [color, ...recentColors].slice(0, 5); // Keep the last 5 colors
      setRecentColors(updatedColors);
      localStorage.setItem("recentColors", JSON.stringify(updatedColors));
      
      // Pass the new color back to the parent component
      onColorChange(color);
    }
  };

  const handleDeleteColor = (colorToDelete) => {
    const updatedColors = recentColors.filter((c) => c !== colorToDelete);
    setRecentColors(updatedColors);
    localStorage.setItem("recentColors", JSON.stringify(updatedColors));
  };

  return (
    <div className="p-4 bg-white rounded-lg">
      <input
        type="text"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        placeholder="Enter color code"
        className="border rounded p-2 mb-2 w-full"
      />
      <button
        onClick={handleColorChange}
        className="bg-usetheme text-white py-2 px-4 rounded-md"
      >
        Apply Color
      </button>

      <div className="mt-4">
        <h3 className="font-semibold">Recent Colors</h3>
        <div className="flex gap-2 mt-2">
          {recentColors.map((recentColor) => (
            <div key={recentColor} className="flex items-center">
              <div
                className="w-8 h-8 rounded-full cursor-pointer"
                style={{ backgroundColor: recentColor }}
                onClick={() => onColorChange(recentColor)}
              ></div>
              <button
                className="ml-2 text-sm text-red-600"
                onClick={() => handleDeleteColor(recentColor)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
