import AllServicesPage from "../pages/services-management/AllServicesPage";
import CreateServicePage from "../pages/services-management/CreateServicePage";
import UpdateServicePage from "../pages/services-management/UpdateServicePage";



const servicesRoutes = [
  {
    path: 'services',
    element: <AllServicesPage />,
  },
  {
    path: 'services/add-service',
    element: <CreateServicePage />,
  },
  {
    path: 'services/update-service/:id',
    element: <UpdateServicePage />,
  },
];

export default servicesRoutes;
