import React from "react";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";

import AddCompanyForm from "../../components/companies-management-components/AddCompanyForm";

export default function AddCompanyPage() {
  return (
    <>
      <AdminDashboardLayout>
        <div className="px-4">
          <AddCompanyForm />
        </div>
      </AdminDashboardLayout>
    </>
  );
}
