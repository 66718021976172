import React from "react";
import Root from "../pages/Root";
import authRoutes from "./authRoutes";
import ProtectedRoute from "../ProtectedRoute";
import AdminDashboardRoute from "./adminDashboardRoute";
import profileSettingRoutes from "./profileSettingRoutes";
import companiesManagementRoutes from "./companiesManagementRoutes";
import invoicesManagementRoutes from "./invoicesManagementRoutes";
import settingRoutes from "./settingRoutes";
import userManagementRoutes from "./userManagementRoutes";
import servicesRoutes from "./servicesRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes, // Public routes for authentication (login, signup, etc.)
      {
        path: "/admin-dashboard/",
        element: <ProtectedRoute />, // Protect this route and its children
        children: [
          ...AdminDashboardRoute,
          ...profileSettingRoutes,
          ...companiesManagementRoutes,
          ...invoicesManagementRoutes,
          ...settingRoutes,
          ...userManagementRoutes,
          ...servicesRoutes,
        ],
      },
    ],
  },
];

export default mainRoutes;
