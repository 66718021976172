import React, { useEffect } from "react";
import NewUsersCard from "./NewUsersCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getAllInactiveUser } from "../../store/adminSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

export default function NewUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, message, loading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getAllInactiveUser());
  }, []);

  useEffect(() => {
    if (message === "Status updated successfully") {
      toast.success(message);
      // navigate(-1);
      dispatch(getAllInactiveUser());
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="px-4 py-4 mt-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div
            className={`grid md:grid-cols-3 xl:grid-cols-4 grid-cols-2 gap-4`}
          >
            {data?.length > 0
              ? data?.map((item) => <NewUsersCard key={item._id} item={item} />)
              : "No Users Found"}
          </div>
        )}
      </div>
    </>
  );
}
