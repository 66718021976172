import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearMessage, getServiceByID, updateService } from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";

export default function UpdateServiceForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    buttonText: "",
    addButton: "",
    buttonLink: "",
  });

  const { loading, message, error, statusCode, data } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(getServiceByID(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (data) {
      setFormData({
        title: data.title || "",
        description: data.description || "",
        buttonText: data.buttonText || "",
        addButton: data.addButton || "",
        buttonLink: data.buttonLink || "",
      });

      setImage(data.icon || null);
    }
  }, [data]);

  useEffect(() => {
    if (image) {

      if (typeof image === "string") {
        setImagePreview(image);
      } else {

        const objectUrl = URL.createObjectURL(image);
        setImagePreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
      }
    }
  }, [image]);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { title, description, buttonText, addButton, buttonLink } = formData;

    if (!title || !description || !buttonText || !addButton || !buttonLink) {
      toast.error("All fields are required!");
      return false;
    }

    if (!image) {
      toast.error("Please upload an icon!");
      return false;
    }

    if (typeof image !== "string") {
      const allowedTypes = ["image/png", "image/jpeg" , "image/svg+xml"];
      if (!allowedTypes.includes(image.type)) {
        toast.error("Only PNG or JPEG images are allowed!");
        return false;
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (image.size > maxSizeInBytes) {
        toast.error("Image size should not exceed 5MB!");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("buttonText", formData.buttonText);
      formDataToSend.append("addButton", formData.addButton);
      formDataToSend.append("buttonLink", formData.buttonLink);
      if (typeof image !== "string") {
        formDataToSend.append("icon", image);
      }

      dispatch(updateService({ id, formData: formDataToSend }));
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, statusCode, dispatch, navigate]);

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center py-5">
          <BreadCrums
            breadCrum={[
              { name: "Admin Dashboard", path: "/admin-dashboard/" },
              { name: "Manage Services", path: "/admin-dashboard/services" },
              { name: "Update Service" },
            ]}
          />
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="text-white bg-usetheme text-xs md:text-sm h-12 font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
          >
            {!loading ? (
              "Update Service"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button>
        </div>
      </div>
      <div className="w-full bg-white rounded-xl p-3">
        <h1 className="text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Update Service
        </h1>
        <form
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
          onSubmit={handleSubmit}
        >
          {/* Title and Icon */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Title
              </label>
              <input
                name="title"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                placeholder="Service Title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>

            {/* Description */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Description
              </label>
              <input
                name="description"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                placeholder="Service Description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Button Text and Add Button */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Button Text
              </label>
              <input
                name="buttonText"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                placeholder="Add Now"
                value={formData.buttonText}
                onChange={handleInputChange}
              />
            </div>

            {/* Add Button */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Add Button
              </label>
              <input
                name="addButton"
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                placeholder="true"
                value={formData.addButton}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Button Link */}
          <div className="flex w-full flex-col md:gap-1">
            <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
              Button Link
            </label>
            <input
              name="buttonLink"
              className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
              type="text"
              placeholder="Button Link"
              value={formData.buttonLink}
              onChange={handleInputChange}
            />
          </div>

          {/* Image Upload */}
          <div
            onClick={handleImageClick}
            className="flex flex-col md:flex-row gap-4 items-center border-2 border-[#EBF0ED] p-4 rounded-lg cursor-pointer hover:border-[#9B9B9B]"
          >
            <div className="w-32 h-32 flex items-center justify-center border-2 border-gray-300 rounded-lg overflow-hidden">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="object-cover w-full h-full"
                />
              ) : (
                <span className="text-gray-500">No Image</span>
              )}
            </div>
            <div className="text-sm text-gray-700">
              {image ? "Change Image" : "Upload Icon"}
            </div>
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
        </form>
      </div>
    </>
  );
}
