import React, { useState } from "react";
import { Link } from "react-router-dom";
import { deleteService } from "../../store/adminSlice";
import { useDispatch } from "react-redux";
import DeletePopup from "../../common/DeletePopup";
import ButtonLink from "../buttons/ButtonLink";

export default function ServiceCard({ item, onServiceDeleted }) {
  const dispatch = useDispatch();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedServiceId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteService(selectedServiceId)).then(() => {
      onServiceDeleted(selectedServiceId); // Notify parent after successful deletion
    });
    setShowDeletePopup(false); // Hide the popup after confirmation
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Hide the popup on cancel
  };

  return (
    <>
      <div
        role="alert"
        className="mx-auto max-w-lg rounded-lg border border-stone bg-stone-100 p-4 shadow-lg sm:p-6 lg:p-8"
      >
        <div className="flex items-center gap-4">
          <span className="shrink-0 rounded-full bg-white p-2 text-white">
            <img className="h-6 w-6" src={item.icon} alt="" />
          </span>
          <p className="font-medium sm:text-lg text-black">{item.title}</p>
        </div>

        <p className="mt-4 text-gray-600">{item.description}</p>

        <div className="mt-6 sm:flex sm:gap-4">
          {/* <Link
            to={`update-service/${item._id}`}
            className="inline-block w-full rounded-lg bg-usetheme hover:bg-usetheme_hover px-5 py-3 text-center text-sm font-semibold text-white sm:w-auto"
          >
            Update
          </Link> */}

          <ButtonLink
            to={`update-service/${item._id}`}
            text="Update"
            padding="px-3 py-2"
          />

          {/* <div
            onClick={() => handleDeleteClick(item._id)}
            className="mt-2 inline-block w-full rounded-lg bg-white hover:bg-usetheme transition duration-300 px-5 py-3 text-center text-sm font-semibold text-black hover:text-white sm:mt-0 sm:w-auto cursor-pointer"
          >
            Delete
          </div> */}
          <ButtonLink
            onClick={() => handleDeleteClick(item._id)}
            to={`update-service/${item._id}`}
            text="Delete"
            padding="px-3 py-2"
            activeState="bg-white text-black"
            hoverState="hover:bg-usetheme hover:text-white transition duration-300"
          />
        </div>
      </div>

      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
